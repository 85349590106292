/* -- General Layout -- */

/* Navigation */

#nav,
#nav-left {
  a {
    display: block;
    color: $text-color;
    padding: 0.33334em 0;
    font-size: 1.5em;
    font-weight: 400;
    @media (min-width: 940px) {
      font-size: 1em;
    }
    &:hover {
      background-color: lighten($green, 5%);
    }
  }
  span {
    font-weight: 200;
  }
}

#nav {
  @include nav-position(right);
}

#nav-left {
  @include nav-position(left);
}

/* Toggle class to open menu */

#nav.menu-open {
  @include open(-14rem);
}

#nav-left.menu-open-left {
  @include open(14rem);
}

#nav-links {
  @media (max-width: 940px) {
    display: none;
  }
  a {
    padding-left: 10px;
    color: $text-color;
    font-weight: 300;
  }
}

/* Separator after menu */

#nav-list:after {
  display: block;
  content: "";
  width: 5rem;
  height: 1px;
  margin: 23px auto;
  background-color: $text-color;
}

/* Icon menu */

#nav-menu {
  @include icon-position(right);
  @media (min-width: 940px) {
    display: none;
  }
}

#nav-menu-left {
  @include icon-position(left);
  @media (min-width: 940px) {
    display: none;
  }
}

#menu {
  height: 4px;
  width: 1.5em;
  background-color: $text-color;
  margin-top: 8px;
  &:after,
  &:before {
    content: "";
    display: block;
    position: relative;
    height: 4px;
    width: 1.5em;
    background-color: $text-color;
    transition: all 0.3s ease-in;
  }
  &:before {
    top: -8px;
  }
  &:after {
    top: 4px;
  }
  &.btn-close {
    background: none;
  }
  &.btn-close:before {
    top: 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: $text-color;
  }
  &.btn-close:after {
    top: -4px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: $text-color;
  }
}

/* Main content */

.fixed {
  position: fixed;
  width: 100%;
  @media (min-width: 940px) {
    position: static;
  }
}

#container {
  margin: 0 auto;
}

#header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
  text-align: center;
  height: 5em;
  position: relative;
  a {
    text-decoration: none;
    display: inline-block;
  }
  div {
    margin: 0 auto;
  }
  h1 {
    font-size: 2em;
    padding-bottom: 0;
    span {
      color: $text-light-color;
      font-weight: 300;
    }
  }
}

/* Posts */

#posts {
  li {
    list-style-type: none;
  }
}

#post-page {
  margin-bottom: 1.5em;
  @media (min-width: 940px) {
    margin-bottom: 1.3334em;
  }
}

.post + .post:before {
  display: block;
  content: "";
  width: 5rem;
  height: 1px;
  margin: 23px auto;
  background-color: lighten($text-color, 70%);
}

.by-line {
  display: block;
  color: lighten($text-color, 25%);
  line-height: 1.5em; /* 24px/16px */
  margin-bottom: 1.5em; /* 24px/16px */
  font-weight: 200;
  @media (min-width: 940px) {
    display: block;
    color: lighten($text-color, 25%);
    line-height: 1.3334em; /* 24px/18px */
    margin-bottom: 1.3334em; /* 24px/18px */
    font-weight: 200;
  }
}

img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 24px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

code {
  color: lighten($text-color, 35%);
  background-color: lighten($background-color, 35%);
}

.content {
  text-align: justify;
}

/* Set the vertical rhythm (and padding-left) for lists inside post content */

.content ul,
.content ol {
  line-height: 1.5em; /* 24px/16px */
  padding-left: 1.5em;
  @media (min-width: 940px) {
    line-height: 1.33334em; /* 24px/18px */
  }
}

/* Paginator */

.pagination {
  text-align: center;
  margin: 2.666668em;
  span {
    background-color: darken($background-color, 5%);
    color: $text-color;
  }
  a:hover {
    background-color: lighten($text-color, 5%);
  }
}
.page-item {
  background-color: lighten($text-color, 10%);
  color: $background-color;
  padding: 4px 8px;
  font-weight: 400;
  padding: 0.5em 1em;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

/* Footer */

footer {
  background-color: $background-color;
  color: $text-color;
  text-align: center;
  padding: 0.6667em 0;
}
