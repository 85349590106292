/*- Custom style -*/

// -- Put custom style under this point -- //
@import "experience";

.home {
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
}

.home__headline {
  font-size: 2rem;
  margin-top: 1rem;
}

.home__portrait-photo {
  margin-bottom: 0;
}

.contact__social-link {
  text-decoration: none;
}

.contact__social-link > img {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
}

.content hr {
  border-bottom: 0;
}

.post__infobox {
  background-color: #fdfdfd;
  padding: 0.75rem;
  margin: 1rem 0;
  border: 1px solid $green;
  border-radius: 0.25rem;
}

.post__scopebubble {
  background-color: #fdfdfd;
  padding: 0.75rem;
  margin: 1rem 0;
  border: 1px solid #0a5a78;
  border-radius: 0.25rem;
  text-align: center;
  font-weight: 500;

  &:hover {
    background-color: #eee;
  }
}

.post__license {
  font-size: 80%;
}

.category__link {
  font-size: 1rem;
  text-decoration: none;
  background-color: #fdfdfd;
  display: inline-block;
  padding: 0.15rem;
  margin: 0.5rem 0.5rem;
  border: 1px solid $green;
  border-radius: 0.25rem;

  &:hover {
    background-color: #eee;
  }
}

.categories {
  text-align: center;
  border-bottom: 1px solid #ddd;
}
