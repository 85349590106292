// -- Mixins -- //

// Nav menu

@mixin icon-position($position) {
  display: block;
  position: fixed;
  top: 35px;
  #{$position}: 25px;
  z-index: 10;
  height: 24px;
}

@mixin open($x) {
  -webkit-transform: translateX($x);
  -moz-transform: translateX($x);
  -ms-transform: translateX($x);
  transform: translateX($x);
  width: 100%;
  @media (min-width: 940px) {
    width: 30%;
  }
}

@mixin nav-position($position) {
  width: 14rem;
  position: fixed;
  background-color: $background-color;
  top: 0;
  bottom: 0;
  #{$position}: -14rem;
  color: $background-color;
  opacity: 0.95;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  z-index: 1;
  padding: 72px 0;
  text-align: center;
}
